import { cloneDeep, filter } from 'lodash';

import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiService } from "../../common/http.service";
import { LocalStorageService } from '../../authenticate/local-storage.service';

@Injectable({
  providedIn: 'root',
})

export class IComLibsServicesProjectDynamicHomeService {
  constructor(private apiService: ApiService, private storage: LocalStorageService) {
  }

  create(data: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/sections/`, data) as Observable<any>;
  }

  edit(data: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/sections/${data.id}`, data) as Observable<any>;
  }

  delete(id: any): Observable<any> {
    return this.apiService.delete(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/sections/${id}`) as Observable<any>;
  }

  detail(id: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/sections/${id}`) as Observable<any>;
  }

  list(filter: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/sections`, filter) as Observable<any>;
  }

  sortField(id: number, order: number): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/sections/${id}/sort`, { new_order: order }) as Observable<any>;
  }


  listContentType(filter: any): Observable<any> {
    const workerSiteId = this.storage.getTokenData()?.working_site_id;
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/sections/content-types`, filter).pipe(
      map((result: any) => {
        console.log(workerSiteId)
        if (workerSiteId === '8854') {
          return result;
        } else {
          return {
            ...result,
            data: result.data.map((item: any) => ({
              ...item,
              id: `EP_${item.id}`
            }))
          };
        }
      })
    ) as Observable<any>;
  }

  listLayoutByContentType(filter: any): Observable<any> {
    Object.keys(cloneDeep(filter)).forEach(key => {
      if (filter[key].includes('EP_')) {
        filter[key] = filter[key].replace('EP_', '');
      }
    })
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/sections/layouts`, filter) as Observable<any>;
  }

  listStatusByContentType(filter: any): Observable<any> {
    Object.keys(cloneDeep(filter)).forEach(key => {
      if (filter[key].includes('EP_')) {
        filter[key] = filter[key].replace('EP_', '');
      }
    })
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/sections/content-status-name`, filter) as Observable<any>;
  }

  listMembersShip(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/sections/memberships`) as Observable<any>;
  }

  listCity(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/sections/cities`) as Observable<any>;
  }

  listArea(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/sections/content-area`) as Observable<any>;
  }

  getBanners(filter: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/sections/banners`, filter);
  }

  getCampaign(filter: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/sections/achievement`, filter)
  }

  getVouchers(filter: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/sections/vouchers`, filter)
  }

  getBrands(filter: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/sections/vouchers`, filter);
  }

  getSetting(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/settings/SETTING`);
  }

  updateSetting(body = {} as any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/settings/SETTING`, body,
      {
        'Content-Type': 'clear'
      });
  }

  getListMessageType(body = {} as any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/message_types`, body);
  }

  groupNotificaiton(body = {} as any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/noti_groups`, body);
  }


  addNotificationGroup(body = {} as any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/noti_group`, body);
  }

  updateNotificationGroup(body = {} as any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/noti_group/${body.id}`, body);
  }

  addMessageType(body: any) {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/message_type`, body);
  }

  editMessageType(body: any) {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/message_type/${body.id}`, body);
  }

  groupSearchRange(params: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/search/ranges`, params);
  }

  getConfigSearch(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/search`);
  }

  updateConfigSearch(params: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/search`, params);
  }

  getParamMessageConfigs(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/param-message-configs`);
  }

  getMessageTypeCMSOld(params: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/message-config/template`, params);
  }
  
  upsertMessageConfigs(body: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/upsert-message-configs`, body);
  }

  statusMessageConfigs(): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/message-config/status`);
  }

  listMessageConfig(params: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/message-configs`, params);
  }

  listStatusMessageConfig(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/message-config/status`);
  }

  listTypeMessageConfig(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/message-config/type`);
  }

  getConfigDetailMessage(id: string): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixDynamicHome}/v1.0/message-configs/${id}`);
  }
}

