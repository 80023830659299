import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { ApiService } from "../../common/http.service";
import { IProduct, IProductConfig, IProductDiscount, IProductLanguage, IProductPrice, IProductProperties } from "@icom/interfaces";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})

export class IComLibsServicesProductService {
  constructor(private apiService: ApiService) { }

  createProduct(body = {}) {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/products`, body);
  }
  

  updateProduct(id: number, body = {}) {
    return this.apiService.put(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/products/${id}`, body);
  }

  deleteProduct(id: string | number) {
    return this.apiService.delete(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/products/${id}`);
  }

  getProduct(productId: any): Observable<IProduct> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/products/${productId}`).pipe(
      map((res: any) => {
        const data = res ? res?.data : null;
        if (!data) return data;
        return data;
      })
    );
  }

  addLanguageProduct(body = {}) {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/product-contents`, body);
  }

  updateLanguageProduct(body = {} as any) {
    return this.apiService.put(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/product-contents/${body.id}`, body);
  }

  deleteProductLanguage(id: string | number) {
    return this.apiService.delete(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/product-contents/${id}`);
  }

  getProductLanguage(id: string | number): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/products/${id}/contents`)
  }

  addMediaProduct(body = {}) {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/product-medias`, body);
  }

  updateMediaProduct(body = {} as any): Observable<any> {
    return this.apiService.put(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/product-medias/${body.id}`, body);
  }

  uploadMediaProduct(body = {} as any): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/product-medias/upload/images`, body, 
    {
      'Content-Type': 'clear'
    });
  }

  deleteProductMedia(id: string | number): Observable<any> {
    return this.apiService.delete(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/product-medias/${id}`);
  }

  getProductMedia(id: string | number): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/products/${id}/medias`)
  }


  // for product price

  createProductPrice(body = {}) {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/product-prices`, body);
  }

  updateProductPrice(body = {} as any) {
    return this.apiService.put(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/product-prices/${body.id}`, body);
  }


  getProductPrice(productId?: number): Observable<IProductPrice> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/products/${productId}/prices`).pipe(
      map((res: any) => {
        const data = res ? res?.data : null;
        // if (!data) return data;
        // const response: IProductPrice = {
        //   id: data?.id,
        //   payment_method: data?.payment_method,
        //   product_id: data?.product_id,
        //   purchase_cost: data?.purchase_cost,
        //   sale_price: data?.sale_price
        // }
        return data;
      })
    );
  }

  searchProduct(body: any): Observable<any> {
    // const params = { page: 1, limit: 99999 };
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/products`, body) as Observable<any>;
  }

  getItemsBelongToInventoryProduct(idInventory?: string, idProduct?: string): Observable<any> {
    const params = { page: 1, limit: 99999 };
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/inventory/${idInventory}/products/${idProduct}/items`, params) as Observable<any>;
  }

  // for product discount 
  createProductDiscount(body = {}) {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/product-discounts`, body);
  }

  updateProductDiscount(body = {} as any) {
    return this.apiService.put(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/product-discounts/${body.id}`, body);
  }

  getProductDiscount(productId?: number): Observable<IProductDiscount> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/products/${productId}/discounts`).pipe(
      map((res: any) => {
        const data = res ? res?.data : null;
        if (!data) return data;
        const response: IProductDiscount = {
          id: data?.id,
          product_id: data?.product_id,
          type: data?.type,
          value: data?.value,
          maximum_discount_amount: data?.maximum_discount_amount,
          minimum_order_value: data?.minimum_order_value,
          status: data?.status,
          valid_from: data?.valid_from,
          valid_until: data?.valid_until
        }
        return response;
      })
    );
  }

  // for product properties 
  createProductProperties(body = {}) {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/voucher-property`, body);
  }

  updateProductProperties(body = {} as any) {
    return this.apiService.put(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/voucher-property`, body);
  }

  getProductProperties(productId?: number): Observable<IProductProperties> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/voucher-property/${productId}`).pipe(
      map((res: any) => {
        const data = res ? res?.data : null;
        if (!data) return data;
        const response: IProductProperties = {
          id: data?.id,
          product_id: data?.product_id,
          expiry_date: data?.expiry_date,
          expiry_param: data?.expiry_date,
          expiry_policy: data?.expiry_policy,
          voucher_type: data?.voucher_type,
          ...data
        }
        return response;
      })
    );
  }

  // for product config 
  createProductConfig(body = {}) {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/product-configs`, body);
  }

  updateProductConfig(body = {} as any) {
    return this.apiService.put(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/product-configs/${body.id}`, body);
  }


  getProductConfig(productId?: number): Observable<IProductConfig> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/products/${productId}/configs`).pipe(
      map((res: any) => {
        const data = res ? res?.data : null;
        if (!data) return data;
        const response: IProductConfig = {
          id: data?.id,
          product_id: data?.product_id,
          type: data?.type,
          value: data?.value,
          name: data?.name
        }
        return response;
      })
    );
  }

  getOptionType(type: string): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/enums/${type}`) as Observable<any>;
  }

  getBrand(orgId: string): Observable<any> {
    const params = { page: 1, size: 99999 };
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/merchants/orgs/${orgId}/brands`, params) as Observable<any>;
  }

  getStore(): Observable<any> {
    const params = { page: 1, size: 99999 };
    return this.apiService.get(`${environment.merchantUrl}store`, params, {
      'isMerchantService': 'yes',
    }) as Observable<any>;
  }

  getOrg(): Observable<any> {
    const params = { page: 1, size: 99999 };
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/merchants/orgs`, params) as Observable<any>;
  }

  getBrandByOrg(orgId?: string | number): Observable<any> {
    const params = { page: 1, size: 99999 };
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/merchants/orgs/${orgId}/brands`, params) as Observable<any>;
  }

  getStoreByBrand(brandId?: string | number): Observable<any> {
    const params = { page: 1, size: 99999 };
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/merchants/brands/${brandId}/stores`, params) as Observable<any>;
  }

  //for select

  getCategoryForSelect(): Observable<any> {
    const params = { page: 1, size: 99999 };
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/categories`, params) as Observable<any>;
  }

  getCatalogForSelect(): Observable<any> {
    const params = { page: 1, size: 99999 };
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/catalogs`, params) as Observable<any>;
  }

  getGroupForSelect(): Observable<any> {
    const params = { page: 1, size: 99999 };
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/groups`, params) as Observable<any>;
  }

  getInventoryProducts(body: any): Observable<any> {
    const params = { page: 1, size: 99999 };
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/inventory-products`, body) as Observable<any>;
  }

  getProductInventories(id: string | number, params: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/products/${id}/inventories`, params)
  }

  getProductFromCampaigns(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/vouchers/campaigns`)
  }

  filter = {
    mode: 'search',
    q: '',
    limit: 99999,
    offset: 0
  }

  getBrands(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}/v1.1/brands`, this.filter)
  }  

  getPointPartner(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}/v1.1/point-partners`, this.filter)
  }  

  getCampaign(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}/v1.1/achievements`, this.filter)
  } 

  getSearchProduct(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}/v1.1/products/voucher/search`, this.filter)
  } 

  getBanners(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}/v1.1/banners`, this.filter)
  } 
  
  resetQuantiryProduct(idInventory?: string, idProduct?: string): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.0/products/${idProduct}/inventories/${idInventory}/recount-quantity`) as Observable<any>;
  }

  getListImportFile(filter: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/management/list-import-file`, filter)
  } 

  getListStatusImportFile(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/management/list-status`)
  }

  dowloadFile(id: string): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/file-loader/${id}`)
  }

  removeFile(id: string): Observable<any> {
    return this.apiService.delete(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/file-loader/${id}`)
  }

  topupBrands(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/topup-brands`)
  }

  topupProducts(id: string): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/topup-products/${id}`)
  }

  addCoupon(body: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/coupon-release`, body)
  }

  editCoupon(body: any): Observable<any> {
    return this.apiService.put(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/coupon-release`, body)
  }
  
  uploadFile(body: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/resource/file/export`, body)
  }
}
