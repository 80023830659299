import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { ApiService } from "../../common/http.service";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})

export class IComLibsServicesCounponService {
  constructor(private apiService: ApiService) { }

  addCodeManual(body: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/coupon-code`, body)
  }
  
  addCodeAuto(body: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/auto-generate/coupon-code`, body)
  }

  listCounponCode(body: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/coupon-code`, body)
  }  

  getListStatus(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/status/coupon-code`)
  } 
  
  updateStatus(body: any): Observable<any> {
    return this.apiService.put(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/coupon-code`, body)
  }  


  listCounpon(params: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/coupon-release`, params)
  }

  getDetailCoupon(id: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/coupon-release/${id}`)
  }

  activeCoupon(id: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/active/coupon-release/${id}`)
  }

  inActiveCoupon(id: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/deactive/coupon-release/${id}`)
  }

  listTransactionHistories(params: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/coupon/transaction-histories`, params)
  }
  
  listManagementCounponCode(params: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/management/coupon-code`, params)
  }

  getFieldUpdate(id: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/list-field/coupon-release/${id}`)
  }
  
}
