import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormsModule, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyNzFormFieldModule } from '@ngx-formly/ng-zorro-antd/form-field';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'formly-wrapper-nz-select',
  template: `
    <nz-select
      class="w-full"
      nzMode="tags"
      [formControl]="$any(formControl)"
      [formlyAttributes]="field"
      nzPlaceHolder="Nhập và ấn enter"
      (ngModelChange)="props.change && props.change(field, $event)"
      [nzSuffixIcon]="suffixIconTemplate"
    >
    </nz-select>

    <ng-template #suffixIconTemplate>
      <span nz-icon nzType="info-circle" nzTheme="outline" 
            nz-tooltip 
            [nzTooltipTitle]="props.description || 'Nhập giá trị và nhấn Enter để thêm tag'">
      </span>
    </ng-template>

    <div  *ngIf="formControl.errors?.['tagLengthError']" class="mt-2">
      <div class="ant-form-item-explain-error">
          {{ formControl.errors['tagLengthError'] }}
      </div>
    </div>
  `,
  standalone: true,
  imports: [
    NzSelectModule,
    CommonModule,
    ReactiveFormsModule,
    NzSelectModule,
    FormlyNzFormFieldModule,
    FormlySelectModule,
    FormsModule,
    FormlyModule,
    NzIconModule,
    NzToolTipModule
  ]
})
export class FormlyWrapperNzSelectTagsComponent extends FieldWrapper implements OnInit {
  ngOnInit() {
    if (!Array.isArray(this.formControl.value)) {
      this.formControl.setValue([]);
    }
    if (this.props.maxLengthItem) {
      this.formControl.setValidators(this.tagLengthValidator());

    }
  }

  onChange(values: string[]): void {
    this.formControl.setValue(values);
    this.formControl.updateValueAndValidity();
  }

  tagLengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const tags = control.value as string[];
      if (!tags || tags.length === 0) {
        return null;
      }

      for (const tag of tags) {
        if (tag.length < 5 || tag.length > 10) {
          return { tagLengthError: 'Mỗi tag phải có độ dài từ 5 đến 10 ký tự' };
        }
      }

      return null;
    };
  }
}
