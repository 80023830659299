import { NgIf, NgClass, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR, FormControlDirective, ReactiveFormsModule } from '@angular/forms';
import { IComLibsServicesProjectCampaignV3Service } from '@icom/services/projects';
import { FieldType, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile, NzUploadModule } from 'ng-zorro-antd/upload';
import { Observable, Observer, lastValueFrom } from 'rxjs';
@Component({
  selector: 'icom-libs-core-components-formly-upload',
  template: `
    <nz-form-item class="flex-col">
      <nz-form-label *ngIf="to.label" class="!text-left" [nzRequired]="to.required">{{ to.label }}</nz-form-label>
      <div class="nz-input-group">
        <nz-upload
          class="avatar-uploader {{props.className}}"
          ngDefaultControl
          [formControl]="$any(formControl)"
          [formlyAttributes]="field"
          [nzAccept]="props['accept']"
          [nzShowUploadList]="false"
          [nzDisabled]="props.nzDisabled || props.disabled || formControl.disabled"
          [nzMultiple]="false"
          [(nzFileList)]="fileList"
          nzListType="picture-card"
          [nzBeforeUpload]="beforeUpload"
          (nzChange)="handleUploadChange($event)">
          
          <ng-container *ngIf="!avatarUrl">
            <div class="flex flex-col justify-center items-center w-full">
              <div class="flex items-center">
                <span class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'upload'"></span>
                <div class="ant-upload-text ml-2">Tải lên</div>
              </div>
            </div>
          </ng-container>
          
          <!-- Hiển thị ảnh và nút Xóa -->
          <div class="relative object-contain w-full h-full group"  *ngIf="avatarUrl">
            <img class="w-full h-full" style="object-fit: contain;" [src]="avatarUrl" />
            <button *ngIf="avatarUrl && !props.nzDisabled && !props.disabled && !formControl.disabled"  class=" absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <span nz-icon nzType="delete" nzTheme="outline" (click)="removeImage($event)"></span>
            </button>
          </div>
        </nz-upload> 
        <span [hidden]="props.nzDisabled || props.disabled || formControl.disabled" style="color: #00000073">
          Kích thước ảnh dưới {{ formatSize(this.props['size'])  }} <br>
          Chỉ được chọn ảnh có {{props.aspectRatioString ? 'tỉ lệ' : 'kích thước'}}: {{ props.aspectRatioString ? props.aspectRatioString : props.width + 'px x ' + props.height + 'px' }}
        </span>
      </div>

      <div class="ant-form-item-explain" *ngIf="showErr(field)" [ngClass]="{'ant-form-item-explain-connected' : !showErr(field)}">
        <div class="ant-form-item-explain-error">
          <formly-validation-message [field]="field"></formly-validation-message>
        </div>
      </div>
    </nz-form-item>
  `,
  standalone: true,
  imports: [
    NzUploadModule,
    NzButtonModule,
    NzIconModule,
    FormlyModule,
    NgIf,
    NzFormModule,
    NgClass,
    ReactiveFormsModule,
    FormlyNgZorroAntdModule,
    JsonPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: DefaultValueAccessor, useExisting: IcomLibsCoreComponentsFormlyUploadComponent },
    { provide: NG_VALUE_ACCESSOR, useExisting: IcomLibsCoreComponentsFormlyUploadComponent, multi: true },
    FormControlDirective
  ]
})
export class IcomLibsCoreComponentsFormlyUploadComponent extends FieldType implements OnInit {
  protected fileList: NzUploadFile[] = [];
  protected loading = false;
  protected avatarUrl?: string;

  private campaignService = inject(IComLibsServicesProjectCampaignV3Service);
  private msg = inject(NzMessageService);
  private cdr = inject(ChangeDetectorRef);

  ngOnInit() {
    if (this.formControl && (this.formControl as any)['defaultValue']) {
      this.avatarUrl = (this.formControl as any)['defaultValue'];
    }
  }

  protected async handleUploadChange(event: any) {
    const formData = new FormData();
    if (this.props?.['emitFileObj']) {
      this.props?.['emitFileObj'](event.file.originFileObj, this.field);
      this.getBase64(event.file!.originFileObj!, (img: string) => {
        console.log(img)
        this.loading = false;
        this.avatarUrl = img;
        this.cdr.detectChanges();
      });

      return;
    }
    formData.append('image', event.file.originFileObj as File);
    try {
      this.loading = true;
      const result: any = await lastValueFrom(this.campaignService?.uploadImage(formData));
      this.formControl.setValue(result?.data?.path || '');
      this.getBase64(event.file!.originFileObj!, (img: string) => {
        this.loading = false;
        this.avatarUrl = img;
        this.cdr.detectChanges();
      });

    } catch (err) {
    } finally {
      this.loading = false;
    }
  }

  protected showErr(field: FormlyFieldConfig) {
    return field.formControl && field.formControl.invalid && field.formControl.touched;
  }

  beforeUpload = (file: NzUploadFile) => {
    return new Observable((observer: Observer<boolean>) => {
      const isJPG = file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg';
      if (!isJPG) {
        this.msg.error('Hệ thống chỉ hỗ trợ định dạng file png, jpg, jpeg.');
        this.formControl.setValue(this.avatarUrl || '');
        observer.complete();
        return;
      }
      const isLt2M = (file?.size || 0) < this.props['size'];
      if (!isLt2M) {
        this.msg.error(`Hệ thống chỉ hỗ trợ file có dung lượng bằng ${this.formatSize(this.props['size'] as number)}`);
        this.formControl.setValue(this.avatarUrl || '');
        observer.complete();
        return;
      }
      this.checkImageDimension(file).then(dimensionRes => {
        if (dimensionRes === 'size') {
          this.msg.error(`Hệ thống chỉ hỗ trợ file có kích thước bằng ${this.props.width || 80} pixel X ${this.props.height || 80} pixel`);
          this.formControl.setValue(this.avatarUrl || '');
          observer.complete();
          return;
        }
        if (dimensionRes === 'ratio') {
          this.msg.error(`Hệ thống chỉ hỗ trợ file có tỉ lệ ${this.props.aspectRatioString.toString() || 1} : 1`);
          this.formControl.setValue(this.avatarUrl || '');
          observer.complete();
          return;
        }
        observer.next(isJPG && isLt2M && dimensionRes === '');
        observer.complete();
      });
    });
  }

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  handleChange(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        this.getBase64(info.file!.originFileObj!, (img: string) => {
          this.loading = false;
          this.avatarUrl = img;
        });
        break;
      case 'error':
        this.msg.error('Network error');
        this.loading = false;
        break;

    }
  }

  private checkImageDimension(file: NzUploadFile | undefined): Promise<string> {
    return new Promise(resolve => {
      const img = new Image();
      if (!file) {
        return;
      }
      img.src = window.URL.createObjectURL(file as any);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        if (this.props.height && this.props.width) {
          window.URL.revokeObjectURL(img.src);
          resolve((width == (this.props.width || 80)) && (height == (this.props.height || 80)) ? '' : 'size');
        }

        if (this.props.aspectRatio) {
          const actualRatio = width / height;
          const tolerance = this.props.aspectRatioTolerance || 0.1;
          const ratioCheck = Math.abs(actualRatio - this.props.aspectRatio) <= tolerance;
          resolve(ratioCheck ? '' : 'ratio');
        }

        resolve('');
      };
    });
  }

  protected formatSize(sizeInBytes: number): string {
    if (sizeInBytes < 1024) {
      return `${sizeInBytes} B`;
    } else if (sizeInBytes < 1024 * 1024) {
      return `${(sizeInBytes / 1024).toFixed(2)} KB`;
    } else {
      return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
    }
  }

  private removeImage(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.avatarUrl = undefined;
    this.fileList = [];
    this.formControl.setValue(null);
    this.cdr.detectChanges();
  }
}