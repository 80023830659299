export interface IProduct {
    id?: number,
    code?: string;
    name?: string;
    type?: string;
    status?: number;
    show_order?: number | string | null;
    start_time?: string;
    end_time?: string;
    catalog?: string;
    gen_code_type?: string;
    brand_id?: string;
    unlimit?: boolean;
    store_ids?: Array<number>;
    organization_id?: number;
    category_ids?: Array<number>;
    catalog_ids?: Array<number> | number;
    group_ids?: Array<number>;
    import_mode?: string;
    belong_to_merchant?: boolean;
    catalog_show_order?: number | string | null;
    date_range?: Date[];
    medias?: IProductMedia[];
}

export interface IProductLanguage {
    name?: string;
    language?: string;
    detail?: string;
    term_and_condition?: string
    product_id?: number;
    id?: string;
    description?: string;
}

export interface IProductMedia {
    name?: string;
    type?: string;
    product_id?: number;
    id?: string;
    url?: string;
    url_public?: string;
}


export interface IProductPrice {
    id?: string;
    payment_method?: string;
    purchase_cost?: number;
    sale_price?: number;
    product_id?: string;
    pool_id?: number;
    subpool_id?: number;
}


export interface IProductDiscount {
    id?: string;
    product_id?: string;
    type?: string;
    value?: number;
    status?: number;
    valid_from?: Date;
    valid_until?: Date;
    minimum_order_value?: number;
    maximum_discount_amount?: number;
    date_range?: Date[] | string[];
}


export interface IProductConfig {
    id?: string;
    product_id?: number;
    type?: string;
    value?: string;
    name: string;
}


export interface IProductProperties {
    id?: string;
    product_id?: number;
    voucher_type?: string;
    expiry_policy?: string;
    expiry_date?: Date;
    expiry_param?: number;
    voucher_value?: number;
}

export interface ISelectOptions {
    value: string | boolean | number;
    viewValue: string;
}