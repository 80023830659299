import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiService } from "../../common/http.service";

@Injectable({
  providedIn: 'root',
})
export class IComLibsServicesProjectAdminUserService {
  constructor(private apiService: ApiService) { }

  getDetailHistory(id: any) {
    const url = `${environment.apiGatewayUrl}${environment.suffixAdmin}v2.0/activity_logs/${id}`;
    return this.apiService.get(url, {id: id}) as Observable<any>;
  }

  getUserHistoryList(data: any): Observable<any> {
    const url = `${environment.apiGatewayUrl}${environment.suffixAdmin}v2.0/activity_logs`;
    return this.apiService.get(url, data) as Observable<any>;
  }

  getActivityFeatures(): Observable<any> {
    const url = `${environment.apiGatewayUrl}${environment.suffixAdmin}v2.0/activity_features`;
    return this.apiService.get(url) as Observable<any>;
  }

  getActivityActions(): Observable<any> {
    const url = `${environment.apiGatewayUrl}${environment.suffixAdmin}/v2.0/activity_actions`;
    return this.apiService.get(url) as Observable<any>;
  }

  getListDevices(params: any): Observable<any> {
    const url = `${environment.apiGatewayUrl}${environment.suffixAdmin}/v2.0/devices`;
    return this.apiService.post(url, params) as Observable<any>;
  }


  removeDevice(body: any) {
    const url = `${environment.apiGatewayUrl}${environment.suffixAdmin}/v2.0/devices/delete`;
    return this.apiService.post(url, body) as Observable<any>;
  }

  unlockDevice(body: any) {
    const url = `${environment.apiGatewayUrl}${environment.suffixAdmin}/v2.0/devices/unlock`;
    return this.apiService.post(url, body) as Observable<any>;
  }
  
  lockDevice(body: any) {
    const url = `${environment.apiGatewayUrl}${environment.suffixAdmin}/v2.0/devices/lock`;
    return this.apiService.post(url, body) as Observable<any>;
  }

  getAdminDeviceAdminLogs(body: any) {
    const url = `${environment.apiGatewayUrl}${environment.suffixAdmin}/v2.0/devices/histories`;
    return this.apiService.get(url, body) as Observable<any>;
  }

  getListCustomer(params: any): Observable<any> {
    const url = `${environment.apiGatewayUrl}${environment.suffixAdmin}v2.0/customer/list`;
    return this.apiService.get(url, params) as Observable<any>;
  }

  uploadFileCustomer
  (params: any): Observable<any> {
    const url = `${environment.apiGatewayUrl}${environment.suffixAdmin}v2.0/customers/import`;
    return this.apiService.post(url, params,  {
      'Content-Type': 'clear'
    }) as Observable<any>;
  }
  
  getListCustomerDetail(params: any): Observable<any> {
    const url = `${environment.apiGatewayUrl}${environment.suffixAdmin}/v2.0/customers/info`;
    return this.apiService.get(url, params) as Observable<any>;
  }

  getAdmiCustomerAdminLogs(body: any) {
    const url = `${environment.apiGatewayUrl}${environment.suffixAdmin}v2.0/customers/histories`;
    return this.apiService.get(url, body) as Observable<any>;
  }
  
  lockUser(body: any) {
    const url = `${environment.apiGatewayUrl}${environment.suffixAdmin}/v2.0/customers/lock`;
    return this.apiService.post(url, body) as Observable<any>;
  }

  unlockUser(body: any) {
    const url = `${environment.apiGatewayUrl}${environment.suffixAdmin}/v2.0/customers/unlock`;
    return this.apiService.post(url, body) as Observable<any>;
  }

  exportUser(body: any) {
    const url = `${environment.apiGatewayUrl}${environment.suffixAdmin}/v2.0/customers/export`;
    return this.apiService.post(url, body) as Observable<any>;
  }
}
