import { Component, OnInit } from '@angular/core';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { FieldType, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR, FormControlDirective, ReactiveFormsModule } from '@angular/forms';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { NzFormModule } from 'ng-zorro-antd/form';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { NzUploadModule } from 'ng-zorro-antd/upload';

@Component({
  selector: 'icom-libs-core-components-formly-datepicker',
  template: `
    <nz-form-item class="ant-form-item ant-row {{ to.className }}">
      <nz-form-label *ngIf="to.label" [nzRequired]="to.required">{{ to.label }}</nz-form-label>
      <nz-form-control class="ant-form-item-control">
      <ng-container *ngIf="!to.readonly; else readonlyTemplate">
      <nz-date-picker 
        [formControl]="$any(formControl)"
        [nzMode]="props.Mode"
        [nzAllowClear]="(props.nzAllowClear === false)  ?  false :  true"
        [nzAutoFocus]="props.AutoFocus"
        [nzBackdrop]="props.Backdrop"
        [nzDisabled]="props.Disabled || props.disabled || formControl.disabled"
        [nzDisabledDate]="props.DisabledDate"
        [nzPopupStyle]="props.PopupStyle"
        [nzDropdownClassName]="props.DropdownClassName"
        [nzSize]="props.Size"
        [nzFormat]="props.format"
        [nzInputReadOnly]="props.InputReadOnly"
        [nzPlaceHolder]="'Chọn thời gian'"
        [nzBorderless]="props.Borderless"
        [nzSuffixIcon]="props.SuffixIcon"
        [nzInline]="props.Inline"
        (nzOnOpenChange)="props.OnOpenChange?.($event, field)"
        [nzDisabledTime]="props.DisabledTime"
        [nzShowTime]="props.ShowTime"
        [nzShowToday]="props.ShowToday"
        [nzShowNow]="props.ShowNow"
        (nzOnOk)=" props.OnOk?.($event, field)"
        (ngModelChange)="onDateChange($event)"
        ngDefaultControl>
      </nz-date-picker>
      </ng-container>
      <ng-template #readonlyTemplate>
          <span>{{ formControl.value | date: to.format }}</span>
        </ng-template>

      <div class="ant-form-item-explain" *ngIf="showErr(field)" [ngClass]="{'ant-form-item-explain-connected' : !showErr(field)}">
          <div class="ant-form-item-explain-error">
            <formly-validation-message [field]="field"></formly-validation-message>
          </div>
        </div>
      </nz-form-control>
      
    </nz-form-item>

  `,
  standalone: true,
  imports: [
    NzDatePickerModule,
    ReactiveFormsModule,
    FormlyNgZorroAntdModule,
    NzFormModule,
    NgIf,
    FormlyNgZorroAntdModule,
    FormlyModule,
    NgIf,
    NzFormModule,
    NgClass,
    DatePipe
  ],
  providers: [
    { provide: DefaultValueAccessor, useExisting: IcomLibsCoreComponentsFormlyDatepicker },
    { provide: NG_VALUE_ACCESSOR, useExisting: IcomLibsCoreComponentsFormlyDatepicker, multi: true },
    FormControlDirective
  ],
})
export class IcomLibsCoreComponentsFormlyDatepicker extends FieldType implements OnInit {
  initialValue: any; // Biến lưu trữ giá trị trước khi chọn
  ngOnInit(): void {
    this.initialValue = this.model[this.key as string];
  }

  showErr(field: FormlyFieldConfig) {
    return field.formControl && field.formControl.invalid && field.formControl.touched;
  }

  onDateChange(event: Date): void {
    if (this.props.validateChange && !this.props.validateChange(this.field, event)) {
      this.formControl.setValue(this.initialValue);
      return;
    }
    if (!this.field?.props?.emitTimeLocal) {
      this.props.change && this.props.change(this.field, event);
      return;
    }
    const localDate = new Date(event.getTime() - (event.getTimezoneOffset() * 60000));
    if (this.field?.props?.onlyDay) {
      localDate.setHours(0, 0, 0, 0);
    }
    const formattedDate = localDate.toISOString().slice(0, -1).replace('T', ' ');
    this.formControl.setValue(formattedDate);
    this.props.change && this.props.change(this.field, event);
  }
}
