
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiService } from "../../common/http.service";

@Injectable({
  providedIn: 'root',
})

export class IComLibsServicesProjectCampaignV3Service {
  constructor(private apiService: ApiService) { }

  list(data: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/list`, data) as Observable<any>;
  }

  detail(data: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/detail`, data) as Observable<any>;
  }

  uploadImage(data: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/upload-icon`, data,  {
      'Content-Type': 'clear'
    }) as Observable<any>;
  }


  addEditCampaign(data: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/general`, data) as Observable<any>;
  }

  getTypeMissions(): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/gup2-enum`, {
      "group": "1999"
  }) as Observable<any>;
  }

  getActionMission(): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/action-tag`) as Observable<any>;
  }

  getContributor(): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/contributor`) as Observable<any>;
  }

  getPool(): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/pool`) as Observable<any>;
  }

  getSubPool(body: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/sub-pool`, body) as Observable<any>;
  }

  getRewardLevel(): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/gup2-enum`, {
      "group": "2999"
  }) as Observable<any>;
  }

  getRewardForm(): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/gup2-enum`, {
      "group": "3999"
  }) as Observable<any>;
  }

  getMessageTemplate(): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/message-template`) as Observable<any>;
  }

  getTypeReward(): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/gup2-enum`, {
      "group": "5999"
  }) as Observable<any>;
  }

  getListStatus(): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/gup2-enum`, {
      "group": "8999"
  }) as Observable<any>;
  }

  getListTimeStart(): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/gup2-enum`, {
      "group": "6999"
  }) as Observable<any>;
  }

  getListTimeEnd(): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/gup2-enum`, {
      "group": "7999"
  }) as Observable<any>;
  }

  getListOrderAction(): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/gup2-enum`, {
      "group": "999"
  }) as Observable<any>;
  }

  getListOrderActionBy(): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/gup2-enum`, {
      "group": "4999"
  }) as Observable<any>;
  }

  getListActionType(): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/action-type`) as Observable<any>;
  }

  getListActionParam(id: string): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/options/action-param`, {action_type_code: id}) as Observable<any>;
  }


  uploadFile(body: any): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV4}/upload/image`, body, {
      'Content-Type': 'clear'
    }) as Observable<any>;
  }

  saveConfigGame(body: any): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/game`, body) as Observable<any>;
  }

  updateConfigGame(body: any): Observable<any>  {
    return this.apiService.put(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/game`, body) as Observable<any>;
  }

  getGameById(params: any) {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/games`, params);
  }

  getGame(params: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/games/turn`, params)
  }

  updateGame(body: any): Observable<any> {
    return this.apiService.put(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/games/turn`, body)
  }  

  addGame(body: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/games/turn`, body) as Observable<any>;
  }

  listGameStatus(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/games/status`) as Observable<any>;
  }

  listWebsitePageInviteFriend(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/invite-friends/list-website-page`) as Observable<any>;
  }

  listListCampaignCMSOld(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/list-campaign`) as Observable<any>;
  }

  updateInviteFriend(body: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/invite-friends`, body) as Observable<any>;
  }

  getInviteFriend() {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/invite-friends`) as Observable<any>;
  }

  getListStatistics(body: any) {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/invite-friends/list-statistics`, body) as Observable<any>;
  }

  postGroupPakage(body: any) {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/games/group-package`, body) as Observable<any>;
  }


  getGroupPakage(params: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/games/group-package`, params)
  }

  getCampaignListVoucher(id: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/games/campaign-list-voucher/${id}`)
  }

  listGame(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/games/list-game-code`)
  }
  

}

